import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CreatePackage, GetPackageList, DelPackage } from "./api/index";
import { GetTenantList, ChangeTenant } from "./api/role";
import { closeWebSocket } from './utils/socket'
import {
  Layout,
  Menu,
  Modal,
  Input,
  Select,
  Cascader,
  Button,
  Avatar,
  message,
  Popover,
  Tooltip
} from "antd";
import {
  DesktopOutlined,
  BankFilled,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EllipsisOutlined,
  ContainerOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  SettingFilled,
  AppstoreFilled,
  PieChartOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  AuditOutlined,
  SwapOutlined
} from "@ant-design/icons";
import ApplyInfo from "./component/Index/applyInfo/applyInfo";
import Style from "./Applayout.module.less";
import logo from "./kuishi.png"

const { Content, Sider } = Layout;
const { Option } = Select;

function Applayout() {
  const userInfo = useRef({});
  const [collapsed, setCollapsed] = useState(false);
  const [toggleFalse, setToggleFalse] = useState(true);
  const [sidebar, setSidebar] = useState(false)
  const [selectKet, setSelectKet] = useState(["1"]);
  const [applicationListShow, setApplicationListShow] = useState(false);
  const [dataModalShow, setDataModalShow] = useState(false)
  const [applicationShow, setApplicationShow] = useState(false);
  const [userBoxShow, setUserBoxShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [frontpage, setFrontpage] = useState(true)
  const [myPackage, setMyPackage] = useState([]);
  const [addApplicationData, setAddApplicationData] = useState({
    title: "未命名应用",
  });
  const Navigate = useNavigate();
  const [Info, setInfo] = useState()
  const [open, setOpen] = useState(false);  //更多窗口判断
  const newId = useRef()
  const href = useRef(window.location.href)
  const [dataName, setDataName] = useState()
  const [tenantList, setTenantList] = useState([])


  // 更多窗口
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const items = [
    {
      icon: <BankFilled style={{ fontWeight: "600" }} />,
      label: "首页",
      key: "1",
    },
    {
      icon: <AppstoreFilled style={{ fontWeight: "600" }} />,
      label: "应用",
      key: "2",
    },
    {
      icon: <AuditOutlined style={{ fontWeight: "600" }} />,
      label: "组织",
      key: "5",
    },
    // {
    //   icon: <PieChartOutlined style={{ fontWeight: "600" }} />,
    //   label: "数据",
    //   key: "6",
    // },
  ];
  const items3 = [
    {
      icon: <BankFilled style={{ fontWeight: "600" }} />,
      label: "首页",
      key: "1",
    },
    {
      icon: <AppstoreFilled style={{ fontWeight: "600" }} />,
      label: "应用",
      key: "2",
    },
    // {
    //   icon: <PieChartOutlined style={{ fontWeight: "600" }} />,
    //   label: "数据",
    //   key: "6",
    // },
  ];
  const items2 = [
    // {
    //   icon: <SettingFilled style={{ fontWeight: "600" }} />,
    //   label: "管理",
    //   key: "3",
    // },
    {
      icon: <DesktopOutlined style={{ fontWeight: "600" }} />,
      label: "账户名称",
      key: "4",
    },
  ];
  const options = [
    {
      value: "1",
      label: "工作区可见",
    },
    {
      value: "2",
      label: "指定成员可见(默认管理员可见)",
    },
  ];

  useEffect(() => {
    // userInfo.current = JSON.parse(localStorage.getItem("userInfo"));
    if (localStorage.getItem("userInfo")) {
      userInfo.current = JSON.parse(localStorage.getItem("userInfo"));
    }else{
      Navigate("/login");
    }
  }, []);

  useEffect(() => { 
    if (href.current.includes('dashboard')) {
      setSelectKet('2')
    } else if (href.current.includes('personalCenter')) {
      setSelectKet('4')
    }
    // else if (href.current.includes('dataSet')) {
    //   setSelectKet('6')
    // }
    else { 
      setSelectKet('1')
    }
  }, [href])

  const menuClick = (e) => {
    switch (e.key) {
      case "1":
        Navigate("/");
        setSelectKet("1");
        setApplicationListShow(false);
        setDataModalShow(false)
        setUserBoxShow(false);
        break;
      case "2":
        // showModal();
        setDataModalShow(false)
        setApplicationListShow(true);
        getPackageList();
        setUserBoxShow(false);
        break;
      case "3":
        setSelectKet("3");
        Navigate("administration");
        setApplicationListShow(false);
        setDataModalShow(false)
        setUserBoxShow(false);
        break;
      case "4":
        // setSelectKet("4");
        setApplicationListShow(false);
        setDataModalShow(false)
        setUserBoxShow(true);
        break;
      case "5":
        Navigate("/organization");
        break;
      case "6":
        setDataModalShow(true)
        setApplicationListShow(false);
        setUserBoxShow(false);
        break;
      default:
        setSelectKet("1");
      // Navigate("/");
    }
  };
  const toggle = () => {
    setCollapsed(!collapsed);
    if (toggleFalse) {
      setToggleFalse(false);
    } else {
      setTimeout(() => {
        setToggleFalse(true);
      }, 100);
    }
  };

  const switchSidebar = () => {
    setSidebar(!sidebar);
    setFrontpage(!frontpage)
    if (toggleFalse) {
      setToggleFalse(false);
    } else {
      setTimeout(() => {
        setToggleFalse(true);
      }, 100);
    }
  };

  const addApplication = () => {
    setApplicationShow(true);
  };

  // 创建应用包
  const submit = async () => {
    let res = await CreatePackage({
      creatorId: userInfo.current.id,
      name: addApplicationData.title,
    });
    if (res.code === 1) {
      newId.current = res.data
      getPackageList();
      setApplicationListShow(false);
      setAddApplicationData({ title: "未命名应用" });
      message.success("应用创建成功", 1, setApplicationShow(false));
    } else {
      message.warning(res.msg);
    }
  };

  //获取包列表
  const getPackageList = async () => {
    let res = await GetPackageList({
      creatorId: userInfo.current.id,
    });
    if (res.code === 1) {
      setMyPackage([...res.data]);
      newId.current && res.data.map((item, index) => {
        if (item.id === newId.current) {
          Navigate("dashboard", { state: item });
        }
      })
    } else {
      message.warning(res.msg);
    }
  };

  // 应用包删除
  const delPackage = async (data) => {
    let res = await DelPackage({
      creatorId: userInfo.current.id,
      packageId: data.id,
    });
    if (res.code === 1) {
      myPackage.length = 0;
      setMyPackage([...myPackage]);
      setApplicationListShow(false);
      setSelectKet('1')
      message.success(res.msg, 1, Navigate("./"));
    } else {
      message.warning(res.msg);
    }
  };

  // 获取租户信息
  const getTenantList = async () => { 
    let res = await GetTenantList({
      userId: userInfo.current.id
    })
    if (res.code === 1) { 
      setIsModalVisible2(true)
      setTenantList([...res.data])
    } else {
      message.warning(res.msg);

    }
  }

  // 切换租户
  const changeTenant = async (id) => { 
    let res = await ChangeTenant({
      tenantId: id,
      userId: userInfo.current.id
    })
    if (res.code === 1) {
      setIsModalVisible2(false)
      setUserBoxShow(false)
      setSelectKet("1");
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("userInfo", JSON.stringify(res.data.user));
      Navigate("/");
      // window.location.reload()
      message.success('切换组织成功')
    } else {
      message.warning(res.msg);
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 跳转个人中心
  const personalCenter = () => {
    setUserBoxShow(false);
    setSelectKet('4')
    Navigate("/personalCenter");
  };

  // 退出登录
  const logOut = () => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
    closeWebSocket()
    Navigate("/login");
  };

  return (
    <Layout className={Style.applayout} style={{ height: "100%" }}>

      {frontpage && <Sider
        collapsed={collapsed}
        trigger={toggle}
        collapsedWidth={56}
        style={{ height: "100vh" }}
        className={`${!collapsed ? Style.default : Style.active}`}
      >
        {/* <img
          className={Style.logo}
          src="https://img1.baidu.com/it/u=3219827707,1421599059&fm=253&fmt=auto&app=120&f=JPEG?w=1463&h=800"
          alt=""
        /> */}
        <div style={{ width: "100%", display: "flex" }}>
          <img className={`${!collapsed ? Style.logo : Style.logoactive}`} src={logo} alt="" />
          <div className={Style.logoname}>{!collapsed ? '魁石KUIS' : ''}</div>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={selectKet}
          mode="inline"
          onClick={(e) => menuClick(e)}
          selectedKeys={selectKet}
          items={userInfo.current.phone === '13609885658' ? items : items3}
          // items={userInfo.current.phone === 'admin' ? items : items3}
        ></Menu>
        <Menu
          theme="dark"
          defaultSelectedKeys={selectKet}
          mode="inline"
          onClick={(e) => menuClick(e)}
          items={items2}
          selectedKeys={selectKet}
          style={{ bottom: "48px", position: "absolute" }}
        ></Menu>
        <div className={Style.collapsed} onClick={toggle}>
          <div className={Style.expand_li}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
              }
            )}
          </div>
        </div>
      </Sider>}

      <div className={`${!collapsed ? Style.none : Style.block}`}>
        <div className={Style.frontpage}>
          <Tooltip xtitle={`${!sidebar ? "收起" : "展开"}`}>
            {
              React.createElement(
                sidebar ? DoubleRightOutlined : DoubleLeftOutlined,
                {
                  className: "Sidebar",
                  onClick: switchSidebar,
                }
              )
            }
          </Tooltip>
        </div>
      </div>
      <Layout className={Style.site_layout}>
        <Content>
          <div className="site-layout-background" style={{ height: "100%" }}>
            <Outlet context={[setSelectKet]} />
            {applicationListShow && (
              <div className={Style.modal}>
                <div
                  className={Style.mask}
                  onClick={() => {
                    setApplicationListShow(false);
                  }}
                ></div>
                <div className={Style.modal_warp}>
                  <div className={Style.modal_header}>
                    <div>选择应用</div>
                    {/* <PlusCircleFilled
                      style={{ color: "#FB9337" }}
                      onClick={addApplication}
                    /> */}
                  </div>
                  <div className={Style.modal_list}>
                    {myPackage.map((item, index) => {
                      return (
                        <div
                          className={Style.modal_item}
                          key={index}
                          onClick={() => {
                            setSelectKet("2");
                            Navigate("dashboard", { state: item });
                            setApplicationListShow(false);
                          }}
                        >
                          <img
                            src={logo}
                            alt=""
                          />
                          <div className={Style.item_name}>{item.name}</div>
                          <div
                            className={Style.operation_icon}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Popover
                              open={open}
                              onOpenChange={(visible) => { handleOpenChange() }}
                              content={
                                <div className={Style.popover_box}>
                                  <div
                                    className={Style.popover_li}
                                    onClick={() => {
                                      setInfo(item)
                                      setIsModalVisible(true);
                                      setOpen(false)
                                    }}
                                  >
                                    <ContainerOutlined
                                      className={Style.popover_icon}
                                    />
                                    <span>基本信息</span>
                                  </div>
                                  {/* <div
                                    className={Style.popover_li}
                                    onClick={() => delPackage(item)}
                                  >
                                    <DeleteOutlined
                                      className={Style.popover_icon}
                                    />
                                    <span>删除</span>
                                  </div> */}
                                </div>
                              }
                              trigger="click"
                            >
                              <EllipsisOutlined />
                            </Popover>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {dataModalShow && (
              <div className={Style.modal} style={{top: '160px'}}>
                <div
                  className={Style.mask}
                  onClick={() => {
                    setDataModalShow(false);
                  }}
                ></div>
                <div className={Style.modal_warp} style={{minHeight: '0', paddingBottom: '10px'}}>
                  <div className={Style.modal_header}>
                    <div>选择数据面板</div>
                  </div>
                  <div className={Style.modal_list}>
                    <div className={Style.modal_item}>
                      <div className={Style.item_name} onClick={() => {
                        setSelectKet("6");
                        setDataModalShow(false);
                        Navigate('dataSet')
                      }}>数据集</div>
                    </div>
                    <div className={Style.modal_item}>
                      <div className={Style.item_name} onClick={() => {
                        setSelectKet("6");
                        setDataModalShow(false);
                        Navigate('dataSource')
                      }}>数据源</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userBoxShow ? (
              <div className={Style.user_modal}>
                <div
                  className={Style.mask}
                  onClick={() => {
                    setUserBoxShow(false);
                  }}
                ></div>
                <div className={Style.user_wrap}>
                  <div className={Style.user_top}>
                    <Avatar
                      size={56}
                      src={logo}
                    />
                    <div className={Style.user_name}>{userInfo.current.phone}</div>
                  </div>
                  <div className={Style.user_item}>
                    <div className={Style.font_color1}>手机</div>
                    <div className={Style.font_color2}>
                      {userInfo.current.phone}
                    </div>
                  </div>
                  <div className={Style.user_item}>
                    <div className={Style.font_color1}>邮箱</div>
                    <div className={Style.font_color2}>
                      {userInfo.current.email}
                    </div>
                  </div>
                  <div className={Style.user_item}>
                    <div className={Style.font_color1}>组织</div>
                    <div className={Style.font_color2}>
                      {userInfo.current.tenantName || ''}
                    </div>
                  </div>
                  <div style={{ color: '#2DDFA1', marginBottom: '5px', marginTop: '10px', cursor: 'pointer' }} onClick={() => getTenantList()}><SwapOutlined style={{ color: '#2DDFA1' }} />切换企业/团队</div>
                  <div className={Style.user_bottom}>
                    <div
                      className={Style.user_Button}
                      onClick={() => personalCenter()}
                    >
                      <DesktopOutlined
                        style={{ fontWeight: "600", marginRight: "5px" }}
                      />
                      个人中心
                    </div>
                    <div className={Style.user_Button} onClick={() => logOut()}>
                      <DesktopOutlined
                        style={{ fontWeight: "600", marginRight: "5px" }}
                      />
                      退出登录
                    </div>
                  </div>
                  {/* <div className={Style.user_right}>
                    <div className={Style.user_right_font}>升级工作区</div>
                  </div> */}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Content>
      </Layout>
      <Modal
        title="创建应用"
        open={applicationShow}
        footer={null}
        width="600px"
        bodyStyle={{
          minHeight: "600px",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          justifyContent: "space-between",
        }}
        onCancel={() => {
          setApplicationShow(false);
          setAddApplicationData({ title: "未命名应用" });
        }}
        destroyOnClose
      >
        <div>
          <div className={Style.add_application_header}>
            <div className={Style.add_application_header_avatar}>
              {addApplicationData.title[0]}
            </div>
            <div className={Style.add_application_header_input_box}>
              <div>应用名</div>
              <Input
                placeholder="请输入"
                defaultValue="未命名应用"
                onChange={(e) => {
                  setAddApplicationData({ title: e.target.value });
                }}
              />
            </div>
          </div>
          <div className={Style.add_application_content}>
            <div>可见范围</div>
            <Cascader
              style={{ width: "100%" }}
              options={options}
              placeholder="工作区可见"
              defaultValue="工作区可见"
            />
          </div>
        </div>
        <div className={Style.add_application_footer}>
          <Button
            style={{ marginRight: "15px" }}
            onClick={() => {
              setApplicationShow(false);
              setAddApplicationData({ title: "未命名应用" });
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={() => submit()}>
            确定
          </Button>
        </div>
      </Modal>
      <ApplyInfo
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        info={Info}
        getPackageList={getPackageList}
        setDataName={setDataName}
      />
      <Modal
        title="切换企业/团队"
        open={isModalVisible2}
        footer={null}
        handleOk={() => { }}
        onCancel={() => { setIsModalVisible2(false) }}
        bodyStyle={{
          width: "500px",
          height: "470px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          justifyContent: "space-between",
        }}>
        <div>
          {tenantList.map((item, index) => { 
            return (
              <div className={Style.corp_block} key={index}>
                <div className={Style.corp_desc}>我创建的企业/团队</div>
                <div className={Style.corp_wrapper}>
                  <div className={Style.corp_name}>{item.tenantName}</div>
                  <div className={Style.corp_op} style={{ cursor: 'pointer' }} onClick={() => changeTenant(item.id)}>进入企业/团队</div>
                </div>
              </div>
            )
          })}
          {tenantList.map((item, index) => {
            if (item.currentTenant) { 
              return (
                <div className={Style.corp_block}>
                  <div className={Style.corp_desc}>我加入的企业/团队</div>
                  <div className={Style.corp_wrapper}>
                    <div className={Style.corp_name}>{item.tenantName}</div>
                    <div className={Style.corp_op}>当前所在的企业/团队</div>
                  </div>
                  <div className={Style.corp_button}>加入企业/团队</div>
                </div>
              )
            }
          })}
        </div>
      </Modal>
    </Layout>
  );
}

export default Applayout;
